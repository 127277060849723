export default defineNuxtRouteMiddleware((to, from) => {
  const isHomePage = to.path === "/";
  const fromHomePage = from.path === "/";
  const resetState = from.meta.resetState ?? false;
  const redirect =
    (fromHomePage ? to.meta.redirect : from.meta.redirect) ?? true;
  const allowForms = getStateValue("allowForms", false);

  if (!redirect) {
    return; // Do nothing if redirect is false
  }

  if (resetState) {
    window.onbeforeunload = null; // Remove any unload event handlers
    if (!isHomePage) {
      clearNuxtState(); // Clear the Nuxt state
      return navigateTo("/"); // Redirect to the homepage
    }
    return; // Do nothing if resetState is true and redirect is false
  }

  if (!isHomePage && !allowForms) {
    return navigateTo("/"); // Redirect to the homepage if none of the above conditions are met
  }
});
